.main
  background-image: url("../../../Assets/Images/MissionPage/MainBlock/MissionMain.webp")
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  height: 820px
  padding: 180px 0
  &_wrap
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: flex-start
    height: 100%
.title
  color: #FFF
  font-family: Gilroy, sans-serif
  font-size: 40px
  font-style: normal
  font-weight: 600
  line-height: normal
  width: 700px
  margin: 0
.desc
  color: var(--Colors-Foreground-fg-disabled, #98A2B3)
  font-family: Gilroy, sans-serif
  font-size: 16px
  font-style: normal
  font-weight: 400
  line-height: normal
.link
  display: flex
  align-items: center
  text-decoration: none
  gap: 8px
  cursor: pointer
  &:hover
    .link_icon
      transform: rotate(360deg)
      transition: transform .5s ease-in-out
  &_desc
    margin: 0
    color: var(--Colors-Foreground-fg-disabled, #98A2B3)
    font-family: Gilroy, sans-serif
    font-size: 14px
    font-style: normal
    font-weight: 400
    line-height: normal
@media(max-width: 1200px)
  .main
    padding: 100px 0
    background-image: url('../../../Assets/Images/MissionPage/MainBlock/MissionMainTablet.webp')
@media(max-width: 720px)
  .main
    height: 666px
    padding: 40px 0
    background-image: url(  "../../../Assets/Images/MissionPage/MainBlock/MissionMainMb.webp")
  .title
    font-size: 28px
    width: 100%
  .link
    display: none