.posts
  background: var(--Component-colors-Alpha-alpha-black-100, #000)
  display: flex
  flex-direction: column
  gap: 20px
  height: 1050px
.postsGrid
  display: grid
  align-items: center
  justify-content: center
  grid-template-columns: repeat(3, auto)
  height: 100%

.postItem
  background: transparent
  color: white
  padding: 20px
  border: 1px solid rgba(133, 184, 255, 0.20)
  width: 100%
  height: 100%
  text-decoration: none

.postImage
  width: 100%
  height: 250px
  border-radius: 5px
  object-position: center
  object-fit: cover

.postAuthor
  font-size: 14px
  color: #909090
  margin: 10px 0 5px

.postMessage
  font-size: 16px
  line-height: 1.5
  overflow: hidden
  text-overflow: ellipsis
  display: -webkit-box
  -webkit-line-clamp: 2 // Обмеження двома рядками
  -webkit-box-orient: vertical
.text
  padding: 48px 0
.title
  color: #FFF
  font-family: Gilroy, sans-serif
  font-size: 40px
  font-style: normal
  font-weight: 600
  line-height: normal
.desc
  color: var(--Colors-Foreground-fg-disabled, #98A2B3)
  font-family: Gilroy, sans-serif
  font-size: 14px
  font-style: normal
  font-weight: 400
  line-height: normal
@media(max-width: 1200px)
  .postItem
    width: 240px
@media(max-width: 720px)
  .posts
    height: auto
  .text
    padding: 30px 0
  .title
    font-size: 28px
  .desc
    font-size: 14px
  .postsGrid
    grid-template-columns: repeat(1, auto)
  .postItem
    width: 100%
  .postImage
    height: 280px