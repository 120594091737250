.numbers
  padding: 36px 0
  background-image: url("../../../Assets/Images/MissionPage/Numbers/NumbersBg.webp")
  background-repeat: no-repeat
  background-size: cover
  background-position: center
  &_wrap
    display: flex
    align-items: center
    justify-content: center
    gap: 90px
  &_item
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    &__number
      color: #FFF
      font-family: Gilroy, sans-serif
      font-size: 68px
      font-style: normal
      font-weight: 600
      line-height: normal
    &__text
      color: var(--Colors-Foreground-fg-disabled, #98A2B3)
      font-family: Gilroy, sans-serif
      font-size: 16px
      font-style: normal
      font-weight: 400
      line-height: normal
@media(max-width: 720px)
  .numbers
    padding: 32px 0
    &_wrap
      gap: 28px
    &_item
      &__number
        font-size: 48px
      &__text
        font-size: 12px