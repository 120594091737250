.footer {
  background-color: #000000;
}
.footer_wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 28px 0;
}

.privacy {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}

.logo {
  width: 96.509px;
  height: 24px;
}

.media {
  display: flex;
  gap: 20px;
}

@media (max-width: 720px) {
  .privacy {
    display: none;
  }
}

