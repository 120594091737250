.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 0 140px 0;
  background: var(--Component-colors-Alpha-alpha-black-100, #000);
  overflow: hidden;
}

.yearsNavigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.yearButton {
  background: none;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: color 0.3s;
  border-bottom: 1px solid rgba(208, 213, 221, 0.2);
  color: var(--Colors-Text-text-white, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-bottom: 44px;
}

.yearButton.active {
  border-bottom: 1px solid var(--Colors-Border-border-brand-solid, #1586EF);
  background: linear-gradient(180deg, rgba(5, 144, 209, 0) 0%, rgba(5, 144, 209, 0.25) 100%);
  color: var(--colors-text-text-brand-secondary-700, #177CD3);
}

.slidesContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  overflow: hidden;
}

.slide {
  opacity: 0.5;
  transition: opacity 0.5s;
  display: flex;
  align-items: flex-start;
  width: 600px;
  height: 220px;
  text-align: start;
  border-radius: 10px;
  margin: 10px 10px;
  border: 1px solid #393939;
  background: #000E15;
  padding: 20px;
  position: relative;
}
.slide_title {
  color: #393939;
  text-overflow: ellipsis;
  font-family: Gilroy, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
}
.slide_img {
  opacity: 0.5;
  width: 98px;
  height: 40px;
}

.activeSlide {
  opacity: 1;
  background-color: #001f3f;
  border: 1px solid #142738;
}

.activeSlide .slide_title {
  color: var(--colors-text-text-brand-secondary-700, #177CD3);
}

.activeSlide .slide_img {
  opacity: 1;
}

.activeSlide .square1, .activeSlide .square2, .activeSlide .square3, .activeSlide .square4 {
  background: var(--Colors-Background-bg-brand-solid, #1586EF);
}

.inactiveSlide {
  opacity: 0.5;
}

.slideContent {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}

.square1, .square2, .square3, .square4 {
  width: 8px;
  height: 8px;
  position: absolute;
  background: var(--Colors-Background-bg-brand-solid, #393939);
}

.square1 {
  left: -4px;
  top: -4px;
}

.square2 {
  right: -4px;
  bottom: -4px;
}

.square3 {
  right: -4px;
  top: -4px;
}

.square4 {
  left: -4px;
  bottom: -4px;
}

@media (max-width: 720px) {
  .timeline {
    padding: 32px 0;
  }
  .slide {
    width: 90%;
    height: 180px;
  }
  .slide_title {
    font-size: 16px;
  }
  .yearButton {
    font-size: 14px;
    padding: var(--spacing-md, 8px) var(--spacing-lg, 12px);
    margin-bottom: 20px;
  }
}

