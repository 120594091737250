.header {
  background-color: #000;
  color: #fff;
  position: sticky;
  top: 0;
  z-index: 999;
}
.header_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 72px;
}
.header_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}
.header_logo img {
  width: 128.678px;
  height: 32px;
}
.header_nav ul {
  display: flex;
  list-style: none;
  margin: 0 18px 0 0;
  gap: 30px;
}
.header_nav ul li a {
  text-decoration: none;
  margin: 0 15px;
  cursor: pointer;
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.header_nav ul li a:hover {
  color: #0590D1;
}
.header_controls {
  display: flex;
  align-items: center;
}

.menu_button {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 20px;
  gap: 8px;
}

.menu {
  position: absolute;
  top: 0;
  right: 0;
  color: white;
  padding: 24px;
  list-style: none;
  border-radius: 5px;
  z-index: 999;
  width: 400px;
  height: 100vh;
  box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
  background: var(--Component-colors-Alpha-alpha-black-100, #000);
}
.menu_wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 94%;
}
.menu_titleWrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.menu_titleWrap__title {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}
.menu ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 20px 0;
  margin: 0;
  gap: 13px;
}
.menu li {
  margin: 10px 0;
  cursor: pointer;
  text-decoration: none;
}
.menu li a {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-bottom: 12px;
  border-bottom: 1px solid #151515;
  text-decoration: none;
}
.menu li a:last-child {
  border-bottom: none;
}
.menu li a:hover {
  color: #0590D1;
}

.overflow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(6px);
  z-index: 100;
}

.line {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.line_item {
  background-color: #fff;
  height: 1px;
  transition: width 0.3s ease;
}

.line1,
.line3 {
  width: 16px;
}

.line2 {
  width: 8px;
  transition: width 0.3s ease;
}

.lineOpen {
  width: 16px;
}

.menu_button:hover .line2 {
  width: 16px;
}

.media {
  display: flex;
  gap: 20px;
}

@media (max-width: 1200px) {
  .header_nav {
    display: none;
  }
}
@media (max-width: 720px) {
  .header_logo img {
    width: 96.509px;
    height: 24px;
  }
  .header_wrapper {
    gap: 15px;
  }
  .menu {
    width: 75vw;
  }
}
@media (min-width: 1200px) {
  .menu li:nth-child(-n+3) {
    display: none;
  }
}

