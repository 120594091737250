.pagination {
  display: flex;
  justify-content: center;
  background: var(--Component-colors-Alpha-alpha-black-100, #000);
}

.pagination div {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 10px;
  padding: 0;
}

.pagination a {
  background-color: transparent;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 18px;
  text-decoration: none;
}

.pagination a:hover {
  background-color: #2c2c2c;
}

.pagination a.active {
  background: rgba(5, 144, 209, 0.15);
  color: var(--colors-text-text-brand-secondary-700, #177CD3);
}

.pagination a.prevNext {
  font-weight: bold;
}

.pagination a.disabled {
  color: #aaa;
  pointer-events: none;
  cursor: default;
}

