.inprogress {
  height: 85vh;
  padding: 180px 0;
  background-image: url("../../Assets/Images/Inprogress/InprogressWeb.webp");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

.title {
  color: #FFF;
  font-family: Gilroy, sans-serif;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.desc {
  color: var(--Colors-Foreground-fg-disabled, #98A2B3);
  text-align: center;
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 519px;
}

.button {
  text-decoration: none;
  display: flex;
  width: 240px;
  height: 42px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border: 1px solid var(--Colors-Border-border-primary, #D0D5DD);
  color: var(--colors-base-primary-foreground, #FFF);
  font-family: Gilroy, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 32px 0 0 0;
}

@media (max-width: 720px) {
  .title {
    font-size: 24px;
  }
  .desc {
    font-size: 12px;
    width: 90%;
  }
}

